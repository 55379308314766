import React, {useEffect, useState} from "react"
import { useSearchContext } from "../components/SearchContextProvider"
import ActionCard  from "../components/ActionCard"
import { useLocation } from "@reach/router";
import ShareLinks from "./ShareLinks";
import Seo from "./Seo"

const Share = () => {


  const { filters: { all } } = useSearchContext();
  const location = useLocation();

  const [actions, setActions] = useState<string[]>([]);

  useEffect(() => {

    const urlSearchParameters = new URLSearchParams(location.search)
    const raw = urlSearchParameters.get("actions") 

    if (typeof window !== 'undefined' && raw) {
      setActions(raw.split(","))
    } 
  }, [location]);

  const getTitle = () => {
    return " avec "
      + (actions.length <= 1 ? "cette action" : "ces "+actions.length+" actions")
  }

  const shareUrl = "https://actions-entreprises.shiftyourjob.org/shared"+location.search

  const title = "Je m’engage à agir dans mon entreprise pour le climat"

  const body = `Je m’engage sur `
  + (actions.length <= 1 ? "l'action suivante \n" : `les ${actions.length} action(s) suivantes:\n`)
  + all.filter(a => actions.includes(a.externalID))
    .sort((a, b) => parseInt(a.externalID) - parseInt(b.externalID))
    .map(a => '- ' + a.name.replaceAll('\n', ': ')).join('\n')
  + `\n\nRetrouvez le détail de ma sélection sur le lien`;
  
  const mailBody = `Bonjour,

C’est parti! Moi aussi je m’engage à agir dans mon entreprise pour le climat. 

Je m’engage à mettre  en place ${actions.length <= 1 ? "l'action suivante" : `les ${actions.length} actions suivantes`}:
${all.filter(a => actions.includes(a.externalID)).sort((a, b) => parseInt(a.externalID) - parseInt(b.externalID)).map(a => '- ' + a.name.replaceAll('\n', ': ')).join('\n')}

Pour retrouver le détail de ma sélection, il suffit de cliquer ${shareUrl}.

Une initiative des Shifters, les bénévoles du Shift Project, diffusée lors de l’événement UNPACTE pour la Planète, organisé par le CJD.`

  return (
    <>
      <Seo title="Je m’engage à agir dans mon entreprise pour le climat"/>
      <div className="bg-beige font-sans text-primary pb-10" style={{minHeight: "92vh"}}>
        <h1 className="text-center text-2xl font-black leading-tight pt-6 pb-6">
          Je m'engage <br/> à agir dans mon entreprise pour le climat <br/>
          {getTitle()}
        </h1>
        <ShareLinks url={shareUrl} title={title} body={body} mailBody={mailBody} actionCount={actions.length}/>
        <div className="mx-2 py-4 pb-10 sm:mx-auto max-w-xl  grid grid-cols-1 gap-6 p-2 ">
        {
          all.filter(a => actions.includes(a.externalID))
            .sort((a, b) => parseInt(a.externalID) - parseInt(b.externalID))
            .map(action => (
              <ActionCard key={action.id} action={action} enableSelect={false} />
          ))
        }
        </div>
        <ShareLinks url={shareUrl} title={title} body={body} mailBody={mailBody} actionCount={actions.length} />
      </div>
    </>
  )
}

export default Share
