import React from "react"
import Share from "../components/Share"
import { SearchContextProvider } from "../components/SearchContextProvider"
import Footer from "../components/Footer"
import "../components/layout.css"

function SharePage() {

  return (
    <SearchContextProvider>
      <Share />
      <Footer />
    </SearchContextProvider>
  )
}

export default SharePage
