import React, {useState} from "react"
import {
  FacebookShareButton, FacebookIcon, TwitterShareButton,
  TwitterIcon, LinkedinShareButton, LinkedinIcon, EmailShareButton, EmailIcon
} from "react-share";
import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CopyToClipboard } from 'react-copy-to-clipboard';

type ShareLinksProps = {
  url: string
  title: string
  body: string
  mailBody: string
  actionCount: number
}

const ShareLinks = ({ url, title, body, mailBody, actionCount }: ShareLinksProps) => {

  const [copied, setCopied] = useState(false)

  const fbText = `Moi aussi je m’engage à agir dans mon entreprise pour le climat en mettant en place ${actionCount > 1 ? `ces ${actionCount} actions` : `cette action`} à découvrir. 
Comment agir dans mon entreprise pour le climat ? Une initiative des @LesShifters, les bénévoles du @TheShiftProjectThinkTank, à l'occasion de @unpactepourlaplanete du @CJDFrance`

  const twitterText = `Je m’engage à agir dans mon entreprise pour le climat avec ${actionCount > 1 ? `ces ${actionCount} actions` : `cette action`} à découvrir :`

  return (
    <>
      <div className="text-primary text-sm text-center mb-2">Je partage mes actions sur:</div>
      <div className="flex justify-center mb-6">
        <LinkedinShareButton
          className="mx-3"
          url={url}
          source="https://actions-entreprises.shiftyourjob.org/"
          title={title}
          summary={body}>
          <LinkedinIcon size={50} round />
        </LinkedinShareButton>
        <EmailShareButton
          className="mx-3"
          url={url} subject={title} body={mailBody} separator={`
          
`}>
          <EmailIcon size={50} round />
        </EmailShareButton>
        <TwitterShareButton
          className="mx-3"
          url={url}
          hashtags={["transitionecologique", "changementclimatique"]}
          title={twitterText}
          via="Les_shifters"
          related={["@Les_shifters", "@theShiftPR0JECT", "@cjd_france"]}>
          <TwitterIcon size={50} round />
        </TwitterShareButton>
        <FacebookShareButton
          className="mx-3"
          url={url}
          quote={fbText}
          hashtag="#transitionecologique">
          <FacebookIcon size={50} round />
        </FacebookShareButton>

      </div>
      <div className="text-primary text-sm text-center mb-2 ">Suggestion de text à partager:</div>

      <div className="relative m-2 p-3 bg-white rounded-lg border max-w-xl sm:mx-auto text-gray-600">
        <div className="text-sm text-left " 
          dangerouslySetInnerHTML={{ __html: mailBody.replace(/\n/g, "<br />") }} />
        <CopyToClipboard text={mailBody} onCopy={() => setCopied(true)}>
            <FontAwesomeIcon
              icon={faCopy}
              className={copied ? "hidden" : "absolute top-0 right-4 cursor-pointer"} style={{ height: "47px" }}
            />
        </CopyToClipboard>
        <div className={copied ? "absolute top-2 right-2 cursor-pointer text-red-500 text-sm" : "hidden"}>
          Copié!
        </div>
      
      </div>
      <div className="flex justify-center mb-6 ">
        
      </div>
    </>
  )
}

export default ShareLinks